<template>
  <div class="container">
    <TitleHeader>MI Reporting</TitleHeader>
    <div class="row mb-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body text-left">
            <Form
              class="w-100 text-left p-0 d-flex flex-direction-row align-items-start"
              :validation-schema="schema"
              @submit="handleDateSubmit"
            >
              <DatePicker class="mr-4" name="from_date" label="Date from" />
              <DatePicker class="mr-4" name="to_date" label="To from" />
              <button class="btn btn-success btn-top-margin">
                update
              </button>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-xsm-12 col-md-6 mb-sm-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">
              Applications received
            </h5>
            <LoadingSection
              :loading-state="
                evalutateProperty('compStats?.applicationVolumeQuery')
              "
            >
              <span class="text-large">{{
                evalutateProperty("compStats?.applicationVolumeQuery")
              }}</span>
            </LoadingSection>
          </div>
        </div>
      </div>
      <div class="col-xsm-12 col-md-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">
              Traced pensions received
            </h5>
            <LoadingSection
              :loading-state="
                evalutateProperty('compStats?.pensionTraceVolumeQuery')
              "
            >
              <span class="text-large">{{
                evalutateProperty("compStats?.pensionTraceVolumeQuery")
              }}</span>
            </LoadingSection>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-xsm-12 col-md-6 mb-sm-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">
              Average pension pot size
            </h5>
            <LoadingSection
              :loading-state="
                evalutateProperty('compStats?.averagePensionPotSize?.fundValue')
              "
            >
              <strong>fund value:</strong><br />
              <span class="text-large"
                >£{{
                  formatCurrency(
                    evalutateProperty(
                      "compStats?.averagePensionPotSize?.fundValue"
                    )
                  )
                }}</span
              ><br />
            </LoadingSection>
            <LoadingSection
              :loading-state="
                evalutateProperty(
                  'compStats?.averagePensionPotSize?.transferValue'
                )
              "
            >
              <strong>transfer value:</strong><br />
              <span class="text-large"
                >£{{
                  formatCurrency(
                    evalutateProperty(
                      "compStats?.averagePensionPotSize?.transferValue"
                    )
                  )
                }}</span
              >
            </LoadingSection>
          </div>
        </div>
      </div>
      <div class="col-xsm-12 col-md-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">
              Traces per application
            </h5>
            <LoadingSection
              :loading-state="
                evalutateProperty('compStats?.tracesPerApplication?.min')
              "
            >
              <strong>min:</strong>
              {{
                Math.floor(
                  evalutateProperty("compStats?.tracesPerApplication?.min")
                )
              }}
            </LoadingSection>
            <LoadingSection
              :loading-state="
                evalutateProperty('compStats?.tracesPerApplication?.max')
              "
            >
              <strong>max:</strong>
              {{
                Math.floor(
                  evalutateProperty("compStats?.tracesPerApplication?.max")
                )
              }}
            </LoadingSection>
            <LoadingSection
              :loading-state="
                evalutateProperty('compStats?.tracesPerApplication?.average')
              "
            >
              <strong>average:</strong>
              {{
                Math.floor(
                  evalutateProperty("compStats?.tracesPerApplication?.average")
                )
              }}
            </LoadingSection>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-xsm-12 col-md-6 mb-sm-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-0">
              Pension known
            </h5>
            <LoadingSection
              :loading-state="evalutateProperty('compStats?.pensionKnown')"
            >
              <strong>known:</strong>
              {{
                Math.floor(evalutateProperty("compStats?.pensionKnown?.known"))
              }}<br />
            </LoadingSection>
            <LoadingSection
              :loading-state="evalutateProperty('compStats?.pensionKnown')"
            >
              <strong>unknown:</strong>
              {{
                Math.floor(
                  evalutateProperty("compStats?.pensionKnown?.unknown")
                )
              }}
            </LoadingSection>
          </div>
        </div>
      </div>
      <div class="col-xsm-12 col-md-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-0">
              Signature provided
            </h5>
            <p>Data valid from: 14/10/2021</p>
            <LoadingSection
              :loading-state="evalutateProperty('compStats?.signatureProvided')"
            >
              <strong>with:</strong>
              {{
                Math.floor(
                  evalutateProperty("compStats?.signatureProvided?.yes")
                )
              }}
            </LoadingSection>
            <LoadingSection
              :loading-state="evalutateProperty('compStats?.signatureProvided')"
            >
              <strong>without:</strong>
              {{
                Math.floor(
                  evalutateProperty("compStats?.signatureProvided?.no")
                )
              }}
            </LoadingSection>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="row mb-4">-->
<!--      <div class="col-xsm-12 col-md-6 ">-->
<!--        <div class="card">-->
<!--          <div class="card-body">-->
<!--            <h5 class="card-title mb-0">-->
<!--              Customer chases/reminders-->
<!--            </h5>-->
<!--            <p>The time taken to send a reminder after 10 day pend.</p>-->
<!--            <p>days hours:minutes:seconds</p>-->
<!--            <LoadingSection-->
<!--              :loading-state="evalutateProperty('compStats?.customerReminders')"-->
<!--            >-->
<!--              <span class="text-large">{{-->
<!--                evalutateProperty("compStats?.customerReminders")-->
<!--              }}</span>-->
<!--            </LoadingSection>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-xsm-12 col-md-6 ">-->
<!--        <div class="card">-->
<!--          <div class="card-body">-->
<!--            <h5 class="card-title mb-0">-->
<!--              Provider chases/reminders-->
<!--            </h5>-->
<!--            <p>The time taken to send a reminder after 15 day pend.</p>-->
<!--            <p>days hours:minutes:seconds</p>-->
<!--            <LoadingSection-->
<!--              :loading-state="evalutateProperty('compStats?.providerReminders')"-->
<!--            >-->
<!--              <span class="text-large">{{-->
<!--                evalutateProperty("compStats?.providerReminders")-->
<!--              }}</span>-->
<!--            </LoadingSection>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="row mb-4">-->
<!--      <div class="col-xsm-12 col-md-6 mb-sm-4">-->
<!--        <div class="card">-->
<!--          <div class="card-body">-->
<!--            <h5 class="card-title mb-0">-->
<!--              Acknowledgement of application-->
<!--            </h5>-->
<!--            <p>The time taken to respond to acknowledge application.</p>-->
<!--            <p>days hours:minutes:seconds</p>-->
<!--            <LoadingSection-->
<!--              :loading-state="-->
<!--                evalutateProperty('compStats?.acknowledgementOfApplication')-->
<!--              "-->
<!--            >-->
<!--              <span class="text-large">{{-->
<!--                evalutateProperty("compStats?.acknowledgementOfApplication")-->
<!--              }}</span>-->
<!--            </LoadingSection>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-xsm-12 col-md-6">-->
<!--        <div class="card">-->
<!--          <div class="card-body">-->
<!--            <h5 class="card-title mb-0">-->
<!--              Pension report creation-->
<!--            </h5>-->
<!--            <p>days hours:minutes:seconds</p>-->
<!--            <LoadingSection-->
<!--              :loading-state="-->
<!--                evalutateProperty('compStats?.pensionReportCreation')-->
<!--              "-->
<!--            >-->
<!--              <span class="text-large">{{-->
<!--                evalutateProperty("compStats?.pensionReportCreation")-->
<!--              }}</span>-->
<!--            </LoadingSection>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="row mb-4">-->
<!--      <div class="col-xsm-12 col-md-6 mb-sm-4">-->
<!--        <div class="card">-->
<!--          <div class="card-body">-->
<!--            <h5 class="card-title mb-0">-->
<!--              Time taken to issue pension report-->
<!--            </h5>-->
<!--            <p>-->
<!--              The time taken to issue a pension report once all details are-->
<!--              received.-->
<!--            </p>-->
<!--            <p>days hours:minutes:seconds</p>-->
<!--            <LoadingSection-->
<!--              :loading-state="-->
<!--                evalutateProperty('compStats?.issuePensionReport')-->
<!--              "-->
<!--            >-->
<!--              <span class="text-large">{{-->
<!--                evalutateProperty("compStats?.issuePensionReport")-->
<!--              }}</span>-->
<!--            </LoadingSection>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-xsm-12 col-md-6">-->
<!--        <div class="card">-->
<!--          <div class="card-body">-->
<!--            <h5 class="card-title mb-0">-->
<!--              Service completion email sent-->
<!--            </h5>-->
<!--            <p>-->
<!--              How quickly a service completion email is sent to the customer-->
<!--              after final PR.-->
<!--            </p>-->
<!--            <p>days hours:minutes:seconds</p>-->
<!--            <LoadingSection-->
<!--              :loading-state="evalutateProperty('compStats?.serviceCompletion')"-->
<!--            >-->
<!--              <span class="text-large">{{-->
<!--                evalutateProperty("compStats?.serviceCompletion")-->
<!--              }}</span>-->
<!--            </LoadingSection>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="row mb-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">
              LOA rejected
            </h5>
            <table class="table table-hover text-left">
              <thead>
                <tr>
                  <th scope="col">Email Template</th>
                  <th scope="col">Total</th>
                  <th scope="col">Percentage</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="table-row"
                  v-for="(item, index) in evalutateProperty(
                    'compStats?.loaRejected'
                  )"
                  :key="index"
                >
                  <td scope="row">
                    {{ getTemplateName(item.email_template) }}
                  </td>
                  <td>
                    {{ item.Total }}
                  </td>
                  <td>{{ parseFloat(item.Percentage).toFixed(2) }}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">
              Pension provider split
            </h5>
            <table class="table table-hover text-left">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Count</th>
                  <th scope="col">Percentage</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="table-row"
                  v-for="(item, index) in evalutateProperty(
                    'compStats?.pensionProviderSplit'
                  )"
                  :key="index"
                >
                  <td scope="row">
                    {{ item.name }}
                  </td>
                  <td>
                    {{ item.ProviderCount }}
                  </td>
                  <td>{{ parseFloat(item.ProviderPerc).toFixed(2) }}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12"></div>
    </div>
  </div>
</template>
<script>
import * as Yup from "yup";
import dayjs from "dayjs";
import { mapActions, mapMutations } from "vuex";
import DatePicker from "@/components/form/DatePicker";
import LoadingSection from "@/components/loader/LoadingSection";
export default {
  name: "MI",
  components: {
    DatePicker,
    LoadingSection
  },
  data: () => ({
    stats: {}
  }),
  setup() {
    let yupShape = {};
    yupShape = {
      from_date: Yup.string()
        .required("required")
        .nullable(),
      to_date: Yup.string()
        .required("required")
        .nullable()
    };

    const schema = Yup.object().shape(yupShape);

    return {
      schema
    };
  },
  computed: {
    compStats() {
      return this.stats;
    }
  },
  methods: {
    ...mapActions(["getInsight"]),
    ...mapMutations(["setLoading"]),
    getTemplateName(temp) {
      switch (temp) {
        case "loaWetSignatureNeeded":
          return "Wet signature required";
        case "loaRejectedAvivaResolve":
          return "Data doesn't match";
        case "loaRejectedNoPolicyNumber":
          return "LOA missing Policy no.";
      }
    },
    async handleDateSubmit(params) {
      this.stats = {};
      params.from_date = dayjs(params.from_date).format("YYYY-MM-DD");
      params.to_date = dayjs(params.to_date).format("YYYY-MM-DD");

      await this.loadSequence(params);
    },
    async loadSequence(params = {}) {
      let miArray = [
        // "issuePensionReport",
        // "pensionReportCreation",
        // "serviceCompletion",
        "signatureProvided",
        // "customerReminders",
        // "providerReminders",
        // "timeTakenToIssueReport",
        // "acknowledgementOfApplication",
        "applicationVolumeQuery",
        "pensionTraceVolumeQuery",
        "loaRejected",
        "pensionProviderSplit",
        "tracesPerApplication",
        "pensionKnown",
        "averagePensionPotSize"
      ];

      miArray.map(async type => {
        params.type = type;
        let response = await this.getInsight(params).catch(err =>
          console.log(err)
        );
        if (response.data) {
          Object.assign(this.stats, response.data);
        }
      });
    }
  },
  async created() {
    await this.loadSequence();
  }
};
</script>
<style lang="scss" scoped>
.btn {
  &-top-margin {
    margin-top: 38px;
  }
}
</style>
